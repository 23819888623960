<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>

                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="options.params.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="batchAudit" icon="el-icon-check">通过</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" @click="batchReject" icon="el-icon-close">拒绝</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <template v-if="$perm('league_PublishResult_edit') && scope.row.status === 0">
                    <el-button type="text" @click="doCalc([scope.row.id])" icon="el-icon-check"
                               class="color-success"> 通过
                    </el-button>
                    <el-button type="text" @click="doReject([scope.row.id])" icon="el-icon-close"
                               class="color-danger"> 拒绝
                    </el-button>
                </template>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.PublishResult.add',
                addPerm: 'league_PublishResult_add',
                editUrl: 'league.PublishResult.edit',
                editPerm: 'league_PublishResult_edit',
                delUrl: 'league.PublishResult.delete',
                delPerm: 'league_PublishResult_delete',
                listUrl: 'league.PublishResult.page',
                formLabelWidth: '100px',
                params: {
                    date: null,
                    status: null
                },
                columns: [
                    {
                        prop: "member_id", label: "用户", formatter: row => {
                            return row.member ? row.member.nickname : null
                        }
                    },
                    {
                        prop: "merchant_id", label: "商户名字", formatter: row => {
                            return row.merchant ? row.merchant.name : null
                        }
                    },
                    {
                        prop: "url", label: "视频链接", html: ({row}) => {
                            return row.url ? `<a href="${row.url}" title="点击打开" target="_blank">${row.url}</a>` : null
                        }
                    },
                    {
                        prop: "attaches", label: "截图附件", html: ({row}) => {
                            if (!row.attaches || row.attaches.length <= 0) {
                                return null
                            }
                            const content = row.attaches
                            const result = [];
                            for (const index in content) {
                                result.push(`<a target="_blank" href="${content[index]}"><img class="list-user-avatar" src="${content[index]}" alt="加载失败"/></a>`)
                            }
                            return `<div class="flex-row">${result.toString()}</div>`
                        }
                    },
                    {
                        prop: "admin_id", label: "处理人", formatter: row => {
                            return row.admin ? row.admin.nickname : ''
                        }
                    },
                    {prop: "remark", label: "备注",},
                    {prop: "calc_time", label: "处理时间",},
                    {prop: "result", label: "处理结果",},
                    {
                        prop: "status", label: "审核状态", formatter: row => {
                            return row.status === 0 ? '待审核' : row.status === 1 ? '已完成' : '已拒绝'
                        }
                    },
                    {prop: "create_time", label: "提交时间",}
                ],
            }
        }
    },
    methods: {
        batchAudit: function () {
            const rows = this.$refs.crud.getSelectedRows().filter(item => item.status === 0);
            const ids = rows.map(item => item.id)
            if (ids.length === 0) return this.$message.warning('未选择任何有效数据~')
            this.doCalc(ids)
        },
        batchReject: function () {
            const rows = this.$refs.crud.getSelectedRows().filter(item => item.status === 0);
            const ids = rows.map(item => item.id)
            if (ids.length === 0) return this.$message.warning('未选择任何有效数据~')
            this.doReject(ids)
        },
        doCalc: function (ids) {
            this.$helper._post(this, 'league.PublishResult.calc', {ids: ids, status: 1, result: '已提现'}, resp => {
                this.$message.success(resp.message)
                this.$refs.crud.getLists()
            })
        },
        doReject: function (ids) {
            this.$prompt('请输入拒绝原因', '提示').then(({value}) => {
                if (!value || value.trim().length <= 0) return this.$message.warning("请输入拒绝原因~")
                this.$helper._post(this, 'league.PublishResult.calc', {ids: ids, status: 2, result: value}, resp => {
                    this.$message.success(resp.message)
                    this.$refs.crud.getLists()
                })
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped>

</style>
